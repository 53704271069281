import { RacemapStarter } from './events';
import {
  AcceptedPingInfo,
  ChronoTrackClientMetadata,
  OtherPingInfo,
  RejectedPingInfo,
} from './trackping';
import { PingType, PositionQuality } from './types';

export enum ViewState {
  NONE = 'NONE',
  HOVERING = 'HOVERING',
}

export enum PlacingToShadowtrack {
  NoShadowTrack = 'NoShadowTrack',
  FarAway = 'FarAway',
  Near = 'Near',
  Mapped = 'Mapped',
  OnTrack = 'OnTrack',
}

export enum ReaderType {
  RRTrackbox = 'RACE RESULT Trackbox',
  RRDecoder = 'RACE RESULT Decoder',
  TimekeepingSplit = 'Timekeeping Split',
}

export type PreparedReader = {
  id: string;
  name?: string;
  positionIndex?: number;
  readerType: ReaderType;
  customerId?: string;
  latestRead?: PreparedReaderRead;
  isDummyReader?: boolean;
  location?: {
    lat: number;
    lng: number;
    elv?: number;
  };
  subReaderIds?: Array<string>;
  readCount?: number;
  updatedAt: Date | null;
  placing: PlacingToShadowtrack;
  mappings: Array<{ offset: number; distance: number; lat: number; lng: number; elv?: number }>;
};
export type PreparedReaderWithIndex = PreparedReader & { positionIndex: number };

export type Transponder = {
  timestamp: number;
  boxId: string;
  dummy?: number;
  pingType: PingType;
  customerId: string;
  transponderId: string;
  rssi: number;
  lat: number;
  lng: number;
  alt?: number;
  posState: 'S';
  hits: number;
  index: number;
  minTimestamp: number;
  minRssi: number;
  receivedAt: number;
  time: number;
  offset?: number;
};

export type PreparedTransponder = {
  id: string;
  userId: string;
  customerId: string;
  latestRead: PreparedTransponderRead | null;
  rawLatestRead: RejectedPingInfo | AcceptedPingInfo | OtherPingInfo | null;
  minTimestamp: Date | null;
  updatedAt: Date | null;
  count: number;
  starter?: RacemapStarter;
  startTime?: Date;
  finishTime?: Date;
};

export interface PredictionClient {
  id: string;
  name?: string;
  eventName?: string;
  meta: ChronoTrackClientMetadata;
  openedAt?: Date;
  lastReceiveAt?: Date;
  isOpen: boolean;
}

export type Readers = Map<string, PreparedReader>;
export type Transponders = Map<string, PreparedTransponder>;
export type Reads = Map<string, PreparedRead>;
export type PredictionClients = Map<string, PredictionClient>;

// TODO: Create types based of ping type

export type Read = {
  boxId: string;
  userId: string;
  customerId: string;
  lat: number;
  lng: number;
  pingType: PingType;
  receivedAt: number;
  time: number;
  transponderId?: string;
};

export type PreparedRead = {
  pingType: PingType;
  timestamp: Date;
  rssi?: number;
  receivedAt: Date;
  dropped: Array<string>;
  userId?: string;
  customerId?: string;
  count?: number;
  location?: {
    state?: PositionQuality;
    lat: number;
    lng: number;
    elv?: number;
  };
  readerId: string;
  transponderId?: string;
  accepted?: boolean;
  wasUserDefined?: boolean;
  timingName?: string;
};

export type PreparedReaderRead = Omit<PreparedRead, 'readerId' | 'dropped'>;
export type PreparedTransponderRead = Omit<PreparedRead, 'transponderId'> & {
  location: {
    lat: number;
    lng: number;
    elv?: number;
  };
  offset: number | null;
};

export interface Times {
  eventStartTime: number;
  eventEndTime: number;
  starterStartTime: number | null;
  starterEndTime: number | null;
}
