import TestSelectors from '@racemap/utilities/consts/test_selectors';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useIsAdmin } from '../../lib/customHooks';
import { useStore } from '../../store/reducers';
import { toastSuccess } from '../utils/Utils';
import { AdminLinks } from './AdminLinks';
import { TrackersLinks } from './TrackersLinks';
import { useIsNavLinkActive } from './utils';

export const AdminHeaderLinks = () => {
  const currentUser = useStore((s) => s.users.getter.currentUser());
  const isFake = currentUser != null && currentUser.fakeUser === true;
  const { isActive } = useIsNavLinkActive();

  const { toggleFakeUser } = useStore((s) => s.users.actions);
  const isAdmin = useIsAdmin();

  // Toggle between fake user and admin modes, notifying the user of the switch.
  async function toggleFakeUserState() {
    await toggleFakeUser();

    if (isAdmin) {
      toastSuccess({
        title: 'Admin Feature',
        message: 'You switched to fake user mode!',
      });
    } else {
      toastSuccess({
        title: 'Admin Feature',
        message: 'You switched back to admin mode!',
      });
    }
  }

  return (
    <Nav style={{ width: '100%' }} navbarScroll>
      <Nav.Link
        key="events"
        as={Link}
        to="/admin/events"
        id="nav_link_events"
        active={isActive('/admin/events')}
      >
        Tracking Maps
      </Nav.Link>
      <TrackersLinks />
      <AdminLinks toggleFakeUserState={toggleFakeUserState} />
      <Nav.Link
        key="support"
        id="nav_link_support"
        href="https://docs.racemap.com/"
        target="_blank"
        rel="noopener"
        active={false}
      >
        Support
      </Nav.Link>
      {isFake && (
        <span
          className="nav-link"
          onClick={toggleFakeUserState}
          onKeyUp={(e) => e.key === 'Enter' && toggleFakeUserState()}
        >
          Back to Admin!
        </span>
      )}
    </Nav>
  );
};
