import { BillableItemTypes } from '@racemap/sdk/schema/user';
import { FeatureCollection } from 'geojson';
import { SplitObject, SplitObjectProperties } from './geos';
import { PlacingToShadowtrack } from './prediction';
import { User as _User } from './users';
import { ID } from './utils';

export type Starter = {
  readonly id: ID;
  readonly name: string;
  readonly startNumber: string;
};

export type Event = {
  readonly id: ID;
  readonly name: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly starterIds: Array<string>;
  readonly starters?: Array<Starter>;
};

export type User = _User;

export type SportsStatistics = {
  _id: ID; // id of this doc
  createdAt: Date;
  updatedAt: Date;
  eventType: string; // the class of event (for example running, swimming, triatlon)
  eventCount: number; // events of this type in our database
  startersWithTimes: number;
  startersWithTracks: number;
  startersWithSpeeds: number;
  arithmeticMeanOfAverageSpeedInMPerS: number;
  arithmeticMeanOfDistanceOverAllStartersInM: number;
  medianOfAverageSpeedInMPerS: number;
  medianOfDistanceOverAllStartersInM: number;
  standardDeviationOfAverageSpeedInMPerS: number;
};

export type SalesEmailPayload = {
  name: string;
  email: Array<string>;
  eventName?: string;
  editorLink?: string;
  products: Array<BillableItemTypes>;
  number: number | null;
};

export type TPOMStatistics = {
  readonly pingCount: number;
  readonly validPingCount: number;
  readonly TPOMAlgoRuntime: number;
  readonly acceptedPingCount: number;
  readonly transpondersWithoutPings: number;
  transponderCount: number;
  validPingRatio: number;
  acceptedPingRatio: number;
};

export type LatLngPoint = {
  lat: number;
  lng: number;
  elv?: number;
};

export type DeltaLatLng = {
  dLat: number;
  dLng: number;
};

export type LatLngTimePoint = LatLngPoint & {
  time: Date;
};

export type Point = LatLngTimePoint & {
  elv?: number;
  receivedAt?: Date;
};

export type AppIdWithPoints = {
  appId: string;
  points: Array<Point>;
};

export type TrackIdWithPoints = {
  trackId: string;
  points: Array<Point>;
};

export type PointWithOffset = LatLngTimePoint & {
  offset: number;
};

export type TracksData = {
  tracks: Array<AppIdWithPoints | TrackIdWithPoints>;
};

export type TrackerHistoryData = {
  timestamp?: Date | undefined;
  battery?: number | undefined;
};

/**
 * @deprecated Legacy type
 */
export type Splits = Array<SplitObject>;

interface SplitStartProperties extends SplitObjectProperties {
  coordinateIndex: 0;
}
export interface SplitStartPoint extends SplitObject {
  properties: SplitStartProperties;
}

export enum PingType {
  BoxPing = 'BoxPing',
  TransponderPing = 'TransponderPing',
  TimingPing = 'TimingPing',
  TimePing = 'TimePing',
  SyntheticPing = 'SyntheticPing',
}

export type Pings = Array<Ping>;

export enum PositionQuality {
  Stationary = 'S',
  Moving = 'M',
  Traveling = 'T',
  Inaccurate = 'X',
  Unknown = 'U',
}

export interface Ping {
  lat: number;
  lng: number;
  elv?: number;
  rssi?: number;
  dummy?: boolean;
  boxId: string;
  chipId?: string;
  minRssi?: number;
  minTimestamp?: string;
  pingType: PingType;
  pos_state?: PositionQuality;
  timingId?: string;
  userId?: string;
  customerId: string;
  receivedAt: string;
  hits?: number;
  index?: number;
  timingName?: string;
  wasUserDefined?: boolean;
  transponderId?: string;
  timestamp: string;

  // migration types
  min_time?: string;
  received_at?: string;
  ping_type?: string;
}

export type BoxPing = {
  boxId: string;
  customerid: string;
  lat: number;
  lng: number;
  pingType: PingType.BoxPing;
  receivedAt: number;
  timestamp: number;
};

export type ShadowTrackMapping = {
  lat: number;
  lng: number;
  elv?: number;
  offset: number;
  distance: number;
};

export type Reader = {
  name?: string;
  readerId: string;
  lastRead: Ping;
  location?: {
    lat: number;
    lng: number;
    elv?: number;
  };
  receivedAt: string;
  readsCount: number;
  placing: PlacingToShadowtrack;
  mappings: Array<ShadowTrackMapping>;
};

export type EnginePoints = Array<EnginePoint>;

export type EnginePoint = {
  lat: number;
  lng: number;
  offset: number;
  speed: number;
  timestamp: number;
};

export type GeoJSON = {
  type: string;
};

export type ShadowGeoJSON = FeatureCollection & {
  splits: Splits;
};
