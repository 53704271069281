import * as fab from '@fortawesome/free-brands-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
// import * as fas from '@fortawesome/free-solid-svg-icons';
import { HAIRSP } from '@racemap/utilities/consts/characters';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { VisibilityStates } from '@racemap/utilities/consts/events';
import { USER_ROLE } from '@racemap/utilities/consts/trackers';
import React, { FC } from 'react';
import { Variant } from 'react-bootstrap/esm/types';
import { getStatusColor } from './utils/helpers';

type Props = Omit<FontAwesomeIconProps, 'icon'>;

export const IconPlus: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPlus} {...props} key="plus" />
);
export const IconMinus: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMinus} {...props} key="minus" />
);
export const IconTrash: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTrash} {...props} key="trash" />
);
export const IconErase: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faEraser} {...props} key="eraser" />
);
export const IconSearch: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSearch} {...props} key="search" />
);
export const IconWand: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faWandMagicSparkles} {...props} key="magic" />
);
export const IconAI = IconWand;
export const IconOk: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCheck} {...props} key="check" />
);
export const IconDoubleOk: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCheckDouble} {...props} key="check-double" />
);
export const IconFail: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faXmark} {...props} key="times" />
);
export const IconClose = IconFail;
export const IconRemove = IconFail;
export const IconSquareCheck: FC<Props> = (props) => (
  <FontAwesomeIcon icon={far.faCheckSquare} {...props} key="check-square" />
);

export const IconFailCircle: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faXmarkCircle} {...props} key="times-circle" />
);
export const IconExpand: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faExpand} {...props} key="expand" />
);
export const IconCheckCircle: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCheckCircle} {...props} key="check-circle" />
);
export const IconExclamation: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faExclamation} {...props} key="exclamation" />
);
export const IconExclamationTriangle: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faExclamationTriangle} {...props} key="exclamation-triangle" />
);
export const IconExclamationCircle: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faExclamationCircle} {...props} key="exclamation-circle" />
);
export const IconUser: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faUser} {...props} key="user" />
);
export const IconUserEdit: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faUserEdit} {...props} key="user-edit" />
);
export const IconUserClock: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faUserClock} {...props} key="user-clock" />
);
export const IconUserTie: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faUserTie} {...props} key="user-tie" />
);
export const IconUserGear: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faUserGear} {...props} key="user-gear" />
);
export const IconEmail: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faEnvelope} {...props} name="envelope" key="envelope" />
);
export const IconQRCode: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faQrcode} {...props} name="qrcode" key="qrcode" />
);
export const IconSquare: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSquare} {...props} key="square" />
);
export const IconCaretSquareRight: FC<Props> = (props) => (
  <FontAwesomeIcon icon={far.faCaretSquareRight} {...props} key="caret-square-right" />
);
export const IconCaretSquareLeft: FC<Props> = (props) => (
  <FontAwesomeIcon icon={far.faCaretSquareLeft} {...props} key="caret-square-left" />
);
export const IconLayerGroup: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faLayerGroup} {...props} key="layer-group" />
);
export const IconObjectGroup: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faObjectGroup} {...props} key="object-group" />
);
export const IconOne: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faDiceOne} {...props} key="one" />
);
export const IconEuro: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faEuroSign} {...props} key="euro-sign" />
);
export const IconDollar: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faDollarSign} {...props} key="euro-sign" />
);
export const IconCreativeCommonsNonCommercial: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fab.faCreativeCommonsNc} {...props} key="creative-commons-nc" />
);
export const IconCreativeCommonsNonCommercialEU: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fab.faCreativeCommonsNcEu} {...props} key="creative-commons-nc-eu" />
);
export const IconCreativeCommonsZero: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fab.faCreativeCommonsZero} {...props} key="creative-commons-zero" />
);

export const IconTime: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faClock} {...props} key="clock" />
);
export const IconStar: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faStar} {...props} key="star" />
);
export const IconKey: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faKey} {...props} key="key" />
);
export const IconCopy: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCopy} {...props} key="copy" />
);
export const IconExternal: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faExternalLinkAlt} {...props} key="external-link-alt" />
);
export const IconLink: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faLink} {...props} key="link" />
);
export const IconUnlink: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faUnlink} {...props} key="unlink" />
);

export const IconArrowUp: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleUp} {...props} key="angle-up" />
);
export const IconArrowDown: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleDown} {...props} key="angle-down" />
);
export const IconArrowRight: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleRight} {...props} key="angle-right" />
);
export const IconArrowLeft: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleLeft} {...props} key="angle-left" />
);
export const IconArrowDoubleUp: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleDoubleUp} {...props} key="angle-double-up" />
);
export const IconArrowDoubleDown: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleDoubleDown} {...props} key="angle-double-down" />
);
export const IconArrowDoubleRight: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleDoubleRight} {...props} key="angle-double-right" />
);
export const IconArrowDoubleLeft: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAngleDoubleLeft} {...props} key="angle-double-left" />
);
export const IconArrowsRotate: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faArrowsRotate} {...props} key="arrows-rotate" />
);
export const IconArrowsLeftRight: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faArrowRightArrowLeft} {...props} key="arrow-right-arrow-left" />
);
export const IconArrowsUpDown: FC<Props> = (props) => (
  <FontAwesomeIcon
    icon={fa.faArrowRightArrowLeft}
    rotation={90}
    {...props}
    key="arrow-up-arrow-down"
  />
);
export const IconToggleOn: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faToggleOn} {...props} key="toggle-on" />
);
export const IconToggleOff: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faToggleOff} {...props} key="toggle-off" />
);

export const IconSwitch = ({ on, ...props }: Props & { on: boolean }) => {
  if (on) return <IconToggleOn {...props} style={{ ...props.style, cursor: 'pointer' }} />;
  return <IconToggleOff {...props} style={{ ...props.style, cursor: 'pointer' }} />;
};
export const IconTurnUp: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTurnUp} {...props} key="arrow-turn-up" />
);
export const IconTurnDown: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTurnDown} {...props} key="arrow-turn-down" />
);
export const IconTurnRight: FC<Props> = (props) => <IconTurnDown rotation={90} {...props} />;
export const IconRevert = IconTurnRight;
export const IconUndo: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faUndo} {...props} key="undo" />
);

export const IconSort: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSort} {...props} key="sort" />
);
export const IconSortUp: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSortUp} {...props} key="sort-up" />
);
export const IconSortDown: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSortDown} {...props} key="sort-down" />
);
export const IconAmountSortUp: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSortAmountUp} {...props} key="sort-amount-up" />
);
export const IconAmountSortDown: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSortAmountDown} {...props} key="sort-amount-down" />
);

export const IconLocation: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faLocationArrow} {...props} key="location-arrow" />
);
export const IconLocationPin: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faLocationPin} {...props} key="location-pin" />
);
export const IconLocationDot: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faLocationDot} {...props} key="location-dot" />
);
export const IconWallet: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faWallet} {...props} key="wallet" />
);
export const IconQuestion: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faQuestionCircle} {...props} key="question-circle" />
);
export const IconQuestionOutline: FC<Props> = (props) => (
  <FontAwesomeIcon icon={far.faQuestionCircle} {...props} key="question-circle" />
);
export const IconInfo: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faInfoCircle} {...props} key="info-circle" />
);
export const IconPowerOff: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPowerOff} {...props} key="power-off" />
);
export const IconDownload: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCloudDownloadAlt} {...props} key="cloud-download-alt" />
);
export const IconUpload: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCloudUploadAlt} {...props} key="cloud-upload-alt" />
);
export const IconReload: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRotateRight} {...props} key="rotate-right" />
);
export const IconSync: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRotate} {...props} key="rotate" />
);
export const IconSave: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSave} {...props} key="save" />
);
export const IconExchange: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faExchangeAlt} {...props} key="exchange-alt" />
);
export const IconShoppingCart: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faShoppingCart} {...props} key="shopping-cart" />
);
export const IconCreditCard: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCreditCard} {...props} key="credit-card" />
);
export const IconOpen: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faFolderOpen} {...props} key="folder-open" />
);
export const IconPlay: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPlay} {...props} key="play" />
);
export const IconRocket: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRocket} {...props} key="rocket" />
);
export const IconCompress: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCompress} {...props} key="compress" />
);
export const IconCrosshairs: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCrosshairs} {...props} key="crosshairs" />
);
export const IconMousePointer: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMousePointer} {...props} key="mouse-pointer" />
);

export const IconStripe: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fab.faStripeS} {...props} key="stripe-s" />
);

export const IconSpinnerNoSpin: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSyncAlt} {...props} key="sync-alt" />
);
export const IconSpinner: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSyncAlt} {...props} key="sync-alt" spin />
);
export const IconRefresh: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRedoAlt} {...props} key="redo-alt" />
);
export const IconRepeat: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRepeat} {...props} key="repeat" />
);

export const IconClock: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faClock} {...props} key="clock" />
);
export const IconGear: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCog} {...props} key="cog" />
);
export const IconSetting: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faEllipsisH} {...props} key="ellipsis-h" />
);
export const IconVisible: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faEye} {...props} key="eye" />
);
export const IconInvisible: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faEyeSlash} {...props} key="eye-slash" />
);
export const IconColor: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPaintRoller} {...props} key="paint-roller" />
);
export const IconStart: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faFlagCheckered} {...props} key="flag-checkered" />
);

export const IconMapMarked: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMapMarkedAlt} {...props} key="map-marked-alt" />
);
export const IconMapPin: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMapPin} {...props} key="map-pin" />
);
export const IconArchive: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faArchive} {...props} key="archive" />
);
export const IconRestore: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBoxesPacking} {...props} key="boxes-packing" />
);
export const IconExport: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faFileExport} {...props} key="file-export" />
);
export const IconFile: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faFile} {...props} key="file" />
);
export const IconBell: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBell} {...props} key="bell" />
);
// TODO: use the ios specific share icon (in fontawesome 6)
export const IconShareIOS: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faShareAlt} {...props} key="share-alt" />
);
export const IconShareAndroid: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faShareAlt} {...props} key="share-alt" />
);
export const IconMobile: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMobileAlt} {...props} key="mobile-alt" />
);
export const IconCalculator: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCalculator} {...props} key="calculator" />
);
export const IconMoney: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMoneyBill} {...props} key="money-bill" />
);
export const IconEdit: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faEdit} {...props} key="edit" />
);
export const IconEditPen: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPen} {...props} key="pen" />
);
export const IconApple: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fab.faApple} {...props} key="apple" />
);
export const IconPlayStore: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fab.faGooglePlay} {...props} key="google-play" />
);
export const IconFilter: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faFilter} {...props} key="filter" />
);
export const IconBatteryEmpty: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBatteryEmpty} {...props} key="battery-empty" />
);
export const IconBatteryQuarter: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBatteryQuarter} {...props} key="battery-quarter" />
);
export const IconBatteryHalf: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBatteryHalf} {...props} key="battery-half" />
);
export const IconBatteryThreeQuarter: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBatteryThreeQuarters} {...props} key="battery-three-quarters" />
);
export const IconBatteryFull: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBatteryFull} {...props} key="battery-full" />
);
export const IconLive: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCircle} color={RacemapColors.AltRed} {...props} key="live" />
);
export const IconPast: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBackwardFast} {...props} key="past" />
);
export const IconFuture: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faForwardFast} {...props} key="future" />
);
export const IconRacemap: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faAnglesRight} {...props} key="racemap" />
);

export const IconBattery: FC<Props & { state: number | null }> = ({ state, ...props }) => {
  if (state == null)
    return (
      <IconExclamation
        {...props}
        title={'No Data'}
        style={{ color: RacemapColors.DarkRed, ...props.style }}
      />
    );

  const title = `${Math.floor(state * 100)}${HAIRSP}%`;
  if (state > 0.9)
    return (
      <IconBatteryFull
        {...props}
        title={title}
        style={{ color: RacemapColors.DarkGreen, ...props.style }}
      />
    );
  if (state > 0.75)
    return (
      <IconBatteryThreeQuarter
        {...props}
        title={title}
        style={{ color: RacemapColors.DarkGreen, ...props.style }}
      />
    );
  if (state > 0.5)
    return (
      <IconBatteryHalf {...props} title={title} style={{ color: '#ffc107', ...props.style }} />
    );
  if (state > 0.25)
    return (
      <IconBatteryQuarter {...props} title={title} style={{ color: '#ffc107', ...props.style }} />
    );
  return (
    <IconBatteryEmpty
      {...props}
      title={title}
      style={{ color: RacemapColors.DarkRed, ...props.style }}
    />
  );
};
export const IconPlug: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPlug} {...props} key="plug" />
);
export const IconConnected: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSatelliteDish} {...props} key="satellite-dish" />
);
export const IconSignal: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSignal} {...props} key="signal" />
);
export const IconNetwork: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faGlobe} {...props} key="globe" />
);
export const IconSatellite: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSatellite} {...props} key="satellite" />
);
export const IconRunner: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRunning} {...props} key="running" />
);
export const IconList: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faListAlt} {...props} key="list-alt" />
);
export const IconRuler: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRuler} {...props} key="ruler" />
);
export const IconClear: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTimes} {...props} key="times" />
);

export const IconLock: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faLock} {...props} key="lock" />
);
export const IconLockOpen: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faLockOpen} {...props} key="lock-open" />
);
export const IconDraftingRuler: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faDraftingCompass} {...props} key="drafting-compass" />
);
export const IconGlobeEurope: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faGlobeEurope} {...props} key="globe-europe" />
);
export const IconGlobe: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faGlobe} {...props} key="globe" />
);
export const IconGaugeHigh: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faGaugeHigh} {...props} key="gauge-high" />
);
export const IconSpeed = IconGaugeHigh;

export const IconFax: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faFax} {...props} key="fax" />
);
export const IconTableList: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTableList} {...props} name="table-list" />
);
export const IconTimeline: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTimeline} {...props} name="timeline" />
);
export const IconMap: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMap} {...props} name="map" />
);
export const IconPaintRoller: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPaintRoller} {...props} name="paint-roller" />
);
export const IconRankingStar: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faRankingStar} {...props} name="ranking-star" />
);
export const IconWrench: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faWrench} {...props} name="wrench" />
);
export const IconChartLine: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faChartLine} {...props} name="chart-line" />
);
export const IconToolbox: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faToolbox} {...props} name="toolbox" />
);
export const IconTerminal: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTerminal} {...props} name="terminal" />
);
export const IconSent: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faPaperPlane} {...props} name="paper-plane" />
);
export const IconTimeout: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faStopwatch} {...props} name="stopwatch" />
);
export const IconDuration = IconTimeout;
export const IconWaiting: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faHourglass} {...props} name="hourglass" />
);
export const IconMoon: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMoon} {...props} name="moon" />
);
export const IconSun: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSun} {...props} name="sun" />
);
export const IconSIM: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faSimCard} {...props} name="sim-card" />
);
export const IconTowerCell: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTowerCell} {...props} name="tower-cell" />
);
export const IconTag: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTag} {...props} name="tag" />
);
export const IconCalendar: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCalendar} {...props} name="calendar" />
);
export const IconCalendarXMark: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faCalendarXmark} {...props} name="calendar-day" />
);
export const IconBook: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faBook} {...props} name="book" />
);
export const IconDocs = IconBook;
export const IconInStock: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faWarehouse} {...props} name="warehouse" />
);
export const IconOutOfStock: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faArrowRightFromBracket} {...props} name="arrow-right-from-bracket" />
);
export const IconMobileRetro: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faMobileRetro} {...props} name="mobile-retro" />
);
export const IconShipping: FC<Props> = (props) => (
  <FontAwesomeIcon icon={fa.faTruckFast} {...props} name="truck-fast" />
);

// Visibility States Icons

export const IconVisibilityArchived = IconArchive;
export const IconVisibilityDraft = IconDraftingRuler;
export const IconVisibilityListed = IconVisible;
export const IconVisibilityUnlisted = IconInvisible;
export const IconVisibilityPublic = IconGlobeEurope;
export const IconVisibility: FC<Props & { visibility: VisibilityStates }> = ({
  visibility,
  ...props
}) => {
  switch (visibility) {
    case VisibilityStates.UNLISTED:
      return <IconVisibilityUnlisted {...props} />;
    case VisibilityStates.LISTED:
      return <IconVisibilityListed {...props} />;
    case VisibilityStates.PUBLIC:
      return <IconVisibilityPublic {...props} />;
    case VisibilityStates.ARCHIVED:
      return <IconVisibilityArchived {...props} />;
    default:
      return <IconVisibilityDraft {...props} />;
  }
};

// Authorization States Icons

export const IconAuthorizationPaid = IconDollar;
export const IconAuthorizationFree = IconCreativeCommonsNonCommercial;
export const IconAuthorizationPaidFreeLoadings = IconCreativeCommonsZero;

// Modules Alias

export const IconPrediction = IconTimeline;
export const IconMonitor = IconTableList;
export const IconLeaderboard = IconRankingStar;
export const IconAdvanced = IconWrench;
export const IconAdmin = IconToolbox;
export const IconDatafeed = IconTerminal;
export const IconSponsor = IconStar;
export const IconAdvertising = IconStar;

// Getter
export const getStatusIcon = (status?: Variant, withColor = true) => {
  const style: FontAwesomeIconProps['style'] = {
    color: withColor ? getStatusColor(status) : undefined,
  };

  switch (status) {
    case 'primary':
      return <IconStar style={style} />;
    case 'secondary':
      return <IconQuestion style={style} />;
    case 'success':
      return <IconOk style={style} />;
    case 'danger':
      return <IconFail style={style} />;
    case 'warning':
      return <IconExclamationTriangle style={style} />;
    case 'info':
      return <IconInfo style={style} />;
    case 'dark':
      return <IconMoon style={style} />;
    case 'light':
      return <IconSun style={style} />;
    default:
      return <></>;
  }
};

// User Aliases
export const IconUserAdmin = IconUserGear;
export const IconUserOwner = IconUserTie;
export const IconUserEditor = IconUserEdit;
export const IconUserBorrower = IconUserClock;

export const getUserRoleIcon = (userRole: USER_ROLE | null, isAdmin?: boolean): React.ReactNode => {
  if (isAdmin) return <IconUserAdmin title="admin" />;

  switch (userRole) {
    case USER_ROLE.OWNER:
      return <IconUserOwner title={userRole} />;
    case USER_ROLE.EDITOR:
      return <IconUserEditor title={userRole} />;
    case USER_ROLE.BORROWER:
      return <IconUserBorrower title={userRole} />;
    default:
      return <></>;
  }
};
